import { createSlice } from "@reduxjs/toolkit";
import { createEncryptedAsyncThunk } from "../Utility/APIUtils";

const initialState = {
  comSuccess: false,
  commonResponse: {
    error: false,
    message: "",
  },
  commonDeleteResponse: {
    status: false,
    message: "",
  },
  commonDeleteSucces: false,
  error: "",
  loading: false,
};

export const getBankList = createEncryptedAsyncThunk(
  "commonSlice/getBankList",
  "bank/GetBankList",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
    }),
  }
);

export const getAllServicesList = createEncryptedAsyncThunk(
  "Commission/getAllServicesList",
  "Service/GetServiceMaster",
  {
    prepareBody: (params) => ({
      service_id: params.service_id || 0,
      service_name: params.service_name || '',
      userID: params.currentUser.userGuid,
    }),
  }
);

export const getOperatorServiceCategory = createEncryptedAsyncThunk(
  "Commission/getOperatorServiceCategory",
  "account/GetOperatorServiceCategory",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      api_id: params.api_id || 0,
      service_id: params.service_id || 0,
    }),
  }
);

export const createOperatorDetails = createEncryptedAsyncThunk(
  "Commission/createOperatorDetails",
  "Service/CreateOperatorDetails",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: params.service_id || 0,
      api_id: params.api_id || 0,
      service_category: params.service_category || '',
      operator_id: params.operator_id || '',
      operator_name: params.operator_name || '',
      operator_code: params.operator_code || '',
      operator_display_name: params.operator_display_name || '',
      remark: params.remark || '',
      value1: params.value1 || '',
      value2: params.value2 || '',
      value3: params.value3 || '',
      value4: params.value4 || '',
      value5: params.value5 || ''
    }),
  }
);

export const updateOperatorDetails = createEncryptedAsyncThunk(
  "Commission/updateOperatorDetails",
  "Service/UpdateOperatorDetails",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceCategory: params.service_category || '',
      operatorId: params.id || 0,
      serviceId: params.service_id || 0,
      updateAll: params.updateAll || false,
      value2: params.value2 || '',
      status: params.status || 0
    }),
  }
);

export const getAllOperatorList = createEncryptedAsyncThunk(
  "RechargeSlice/getOperatorBillPayment",
  "Service/GetOperatorList",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      api_id: params.currentService.api_id,
      service_id: params.currentService.serviceId,
      service_category: params.serviceCategory,
      operator_name: params.operator_name || "",
      remark:params.remark ||  "",
      status: params.status || 1
    }),
  }
);

export const getBankListdmt = createEncryptedAsyncThunk(
  "commonSlice/getBankListdmt",
  "Service/GetOperatorList",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      api_id: params.serviceId.api_id,
      service_id: params.serviceId.serviceId,
      service_category: "DMTBANKLIST",
      operator_name: "",
    }),
  }
);
export const getBankListBill = createEncryptedAsyncThunk(
  "commonSlice/getBankListdmt",
  "Service/GetOperatorList",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      api_id: params.serviceId.api_id,
      service_id: params.serviceId.serviceId,
      service_category: "DMTBANKLIST",
      operator_name: "",
    }),
  }
);
export const getBankListAEPS = createEncryptedAsyncThunk(
  "commonSlice/getBankListdmt",
  "Service/GetOperatorList",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      api_id: 1,
      service_id: 4,
      service_category: "DMTBANKLIST",
      operator_name: "",
    }),
  }
);
export const getBankListAEPSTR = createEncryptedAsyncThunk(
  "commonSlice/getBankListAEPSTR",
  "Service/GetOperatorList",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      // userID: params.currentUser.userGuid,
      // api_id: 1,
      // service_id: 2,
      // service_category: "AEPSBANKLIST",
      // operator_name: "",
      userID: params.currentUser.userGuid,
      api_id: params.serviceId.api_id,
      service_id: params.serviceId.serviceId,
      service_category: 'AEPSBANKLIST',
      operator_name: '',
    }),
  }
);

export const getDashboardDetails = createEncryptedAsyncThunk(
  "commonSlice/getDashboardDetails",
  "Service/DashboardTransactionValuesForAdmin",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
    }),
  }
);

export const GetRecentTransactionValuesDashboard = createEncryptedAsyncThunk(
  "commonSlice/GetRecentTransactionValuesDashboard",
  "Service/GetRecentTransactionValuesDashboard",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
    }),
  }
);

export const AddBank = createEncryptedAsyncThunk(
  "commonSlice/AddBank",
  "bank/AddBankDetails",
  {
    prepareBody: (params) => ({
      bankId: params.selectBank.value,
      accountNumber: params.accountNumber,
      ifsc: params.ifscCode,
      userID: params.currentUser.userGuid,
      isActive: true,
      beneficiaryName: params.beneBificiaryName,
      branchName: params.branchName,
      created_By: params.currentUser.userId,
      balanceRequestMode: ["BANK", "CASH", "ADVANCE"],
    }),
  }
);
// export const getUserBankList = createEncryptedAsyncThunk(
//   'commonSlice/getUserBankList',
//   (params) => `bank/GetBankDetailsById?userID=${params.currentUser.userId}`,
//   {
//     method: 'GET',
//   },
// );
export const getUserBankList = createEncryptedAsyncThunk(
  "commonSlice/getUserBankList",
  "bank/GetBankDetailsById",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      bankuserID: `${params.currentUser.userId}`,
    }),
  }
);
export const getBankDetails = createEncryptedAsyncThunk(
  "commonSlice/getBankDetails",
  "bank/GetBankList",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      bankid: Number(params.body.bankID.value1),
    }),
  }
);
// export const getBanklistAdmin = createEncryptedAsyncThunk(
//   'commonSlice/getBanklistAdmin',
//   () => `bank/GetBankDetailsById?userID=${1}`,
//   {
//     method: 'GET',
//   },
// );
export const getBanklistAdmin = createEncryptedAsyncThunk(
  "commonSlice/getBanklistAdmin",
  "bank/GetBankDetailsById",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      bankuserID: "1",
    }),
  }
);
export const DeleteUserBank = createEncryptedAsyncThunk(
  "commonSlice/DeleteUserBank",
  "bank/UpdateBankDetails",
  {
    prepareBody: (params) => ({
      id: params.data.id,
      bankId: params.data.bankId,
      userId: params.currentUser.userGuid,
      beneficiaryName: params.data.beneficiaryName,
      branchName: params.data.branchName,
      accountNumber: params.data.accountNumber,
      ifsc: params.data.ifsc,
      status: "2",
      updated_By: params.currentUser.userId,
      balanceRequestMode: ["BANK", "CASH", "ADVANCE"],
    }),
  }
);
export const UpdateBank = createEncryptedAsyncThunk(
  "commonSlice/UpdateBank",
  "bank/UpdateBankDetails",
  {
    prepareBody: (params) => ({
      id: params.currentBank,
      bankId: params.selectBank.value,
      userID: params.currentUser.userGuid,
      beneficiaryName: params.beneBificiaryName,
      branchName: params.branchName,
      accountNumber: params.accountNumber,
      ifsc: params.ifscCode,
      status: "1",
      updated_By: params.currentUser.userId,
      balanceRequestMode: ["BANK", "CASH", "ADVANCE"],
    }),
  }
);
export const getAdminDashUsers = createEncryptedAsyncThunk(
  "commonSlice/getAdminDashUsers",
  "Dashboard/GETLatestUserReport",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      flag: "Admin",
    }),
  }
);
export const getComplaintReportRetailer = createEncryptedAsyncThunk(
  "commonSlice/getComplaintReportRetailer",
  "account/GetTicketMaster",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ticketuserID: params.currentUser.userId,
    }),
  }
);
export const getDashBoardCountForAdmin = createEncryptedAsyncThunk(
  "commonSlice/getDashBoardCountForAdmin",
  "Service/DashboardCountForAdmin",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      reportUserid: `${params.currentUser.userId}`,
    }),
  }
);
export const getDashBoardCountTransaction = createEncryptedAsyncThunk(
  "commonSlice/getDashBoardCountTransaction",
  "Service/DashboardTransactionValuesForAdmin",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
    }),
  }
);

export const walletBalance = createEncryptedAsyncThunk(
  "commonSlice/walletBalance",
  "account/WalletCheckBalance",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userId: params.currentUser.userId,
    }),
  }
);
export const getDashTransectionCountForAdmin = createEncryptedAsyncThunk(
  "commonSlice/getDashTransectionCountForAdmin",
  "Service/DashboardTransactionValuesForAdmin",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: () => ({}),
  }
);
export const complaintAdd = createEncryptedAsyncThunk(
  "commonSlice/complaintAdd",
  "account/CreateTicket",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      category: params.complaintType,
      type: params.complaintType,
      mobileNo: params.body.mobileNumber,
      subject: params.complaintType,
      transaction_id: params.body.transID,
      description: params.body.complaintMessage,
    }),
  }
);
export const getAllServices = createEncryptedAsyncThunk(
  "commonSlice/getAllServices",
  "Service/GetUserServiceMaster",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      service_userID: params.currentUser.userId,
    }),
  }
);
export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    commonSuccess(state) {
      return { ...state, commonResponse: true };
    },
    commonFailed(state, action) {
      return {
        ...state,
        commonResponse: { error: true, message: action.payload },
      };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return {
        ...state,
        rechargeResponse: { status: true, message: action.payload },
      };
    },
    commDeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    commDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    commDeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: "" },
      };
    },
  },
});

export const {
  commonSuccess,
  commonFailed,
  FetchSuccess,
  FetchFailed,
  commDeleteSuccess,
  commDeleteFailed,
  commDeleteInitial,
} = commonSlice.actions;
export default commonSlice.reducer;
